<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            :color="filterApplied ? 'orange' : 'msaBlue'"
            dark
            v-blur
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>
        Filter
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        <v-row>
          <v-col class="grow"> Filters </v-col>
          <v-col class="shrink">
            <v-btn
              icon
              dark
              @click="
                dialog = false;
                setFilters();
              "
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-row dense>
          <v-col cols="12"> Name </v-col>
          <v-col>
            <v-text-field
              v-model="thisFilters.name"
              outlined
              hide-details
              placeholder="Enter a name"
              dense
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12"> Email </v-col>
          <v-col>
            <v-text-field
              v-model="thisFilters.email"
              outlined
              hide-details
              placeholder="Enter a email"
              dense
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12"> Company Name </v-col>
          <v-col>
            <v-text-field
              v-model="thisFilters.companyName"
              outlined
              hide-details
              placeholder="Enter a company name"
              dense
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12"> Job Title </v-col>
          <v-col>
            <v-text-field
              v-model="thisFilters.jobTitle"
              outlined
              hide-details
              placeholder="Enter a job title"
              dense
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12"> Status </v-col>
          <v-col>
            <v-btn-toggle
              v-model="thisFilters.status"
              mandatory
              dense
              color="msaBlue"
              style="width: 100%"
            >
              <v-btn style="width: 33%" class="text-capitalize" :value="1">
                Active
              </v-btn>
              <v-btn style="width: 33%" class="text-capitalize" :value="0">
                Inactive
              </v-btn>
              <v-btn style="width: 34%" class="text-capitalize" :value="2">
                All
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="clear()"> clear </v-btn>
        <v-btn class="msaBlue white--text" @click="apply()"> apply </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'WAContactsFilter',
  props: {
    filters: {
      type: Object,
    },
  },
  computed: {
    filterApplied() {
      return (
        JSON.stringify(this.thisFilters) !=
        JSON.stringify(this.$constants.CONTACTS_DEFAULT_FILTERS)
      );
    },
  },
  data() {
    return {
      dialog: false,
      thisFilters: {
        ...this.$constants.CONTACTS_DEFAULT_FILTERS,
      },
    };
  },
  methods: {
    clear() {
      this.thisFilters = {
        ...this.$constants.CONTACTS_DEFAULT_FILTERS,
      };
      this.apply();
    },
    apply() {
      this.fixNulls();
      this.$emit('applyFilters', this.thisFilters);
      this.dialog = false;
    },
    fixNulls() {
      if (this.thisFilters.name == null) {
        this.thisFilters.name = '';
      }

      if (this.thisFilters.email == null) {
        this.thisFilters.email = '';
      }

      if (this.thisFilters.companyName == null) {
        this.thisFilters.companyName = '';
      }

      if (this.thisFilters.jobTitle == null) {
        this.thisFilters.jobTitle = '';
      }
    },
    setFilters() {
      this.thisFilters = { ...this.filters };
    },
  },
};
</script>

<style></style>

<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4">
        <SearchBar
          ref="searchBar"
          @search="onSearch($event)"
          searchLabel="Search by contact name..."
        />
      </v-col>
      <v-col class="grow" align="right">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              class="msaBlue white--text"
              v-blur
              v-on="on"
              @click="goToDetail(defaultContactProps)"
              data-testid="add-contact"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>Add Contact</span>
        </v-tooltip>
      </v-col>

      <v-col class="shrink">
        <WAContactsFilter
          :filters="filters"
          ref="filters"
          @applyFilters="applyFilters($event)"
        />
      </v-col>
      <v-col class="shrink">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip, attrs }">
            <v-btn
              color="msaBlue"
              v-bind="attrs"
              v-on="{ ...tooltip }"
              v-blur
              class="white--text"
              @click="gotoImport"
            >
              <v-icon>mdi-plus-box-multiple-outline</v-icon>
            </v-btn>
          </template>
          <span>Mass Import</span>
        </v-tooltip>
      </v-col>
      <v-col class="shrink">
        <ExportContacts :filters="filters" :options="options" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text">
            <v-badge
              color="msaBlue"
              bordered
              :content="itemsCount"
              :value="itemsCount"
              offset-y="20"
              offset-x="-10"
            >
              Contacts
            </v-badge></v-card-title
          >
          <v-data-table
            :headers="headers"
            :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
            :items="items"
            :server-items-length="itemsCount"
            :options.sync="options"
            :no-data-text="
              filtersApplied ? 'No results found' : $vuetify.noDataText
            "
          >
            <template v-slot:[`item.name`]="{ item }">
              <v-row no-gutters>
                <v-col cols="12" class="font-weight-bold">
                  {{ item.name }}
                </v-col>
                <v-col>
                  {{ item.companyName }}
                  <span v-if="item.jobTitle != ''">
                    - {{ item.jobTitle }}
                  </span>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.formsCount`]="{ item }">
              <span
                class="msaBlue--text"
                style="cursor: pointer"
                @click="goToForms(item.id)"
              >
                {{ item.formsCount }}
              </span>
            </template>
            <template v-slot:[`item.documentsCount`]="{ item }">
              <span
                class="msaBlue--text"
                style="cursor: pointer"
                @click="goToAssignedDocuments(item.id)"
              >
                {{ item.documentsCount }}
              </span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-blur
                    text
                    small
                    v-on="on"
                    @click="goToDetail(item)"
                    :data-testid="`edit-contact-${item.firstName}`"
                  >
                    <v-icon color="msaBlue"> mdi-pencil </v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn small text v-on="on" @click="toggleStatus(item)">
                    <v-icon :color="item.status ? 'green' : 'orange'">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{
                    item.status ? 'Click to inactivate' : 'Click to activate'
                  }}
                </span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue';
import ExportContacts from '@/components/WAContacts/ExportContacts.vue';
import WAContactsFilter from '@/components/Filters/WAContactsFilter.vue';
export default {
  name: 'CompanyContactsPage',
  components: {
    SearchBar,
    ExportContacts,
    WAContactsFilter,
  },
  watch: {
    options: {
      handler(oldVal, newVal) {
        const changed = Object.keys(newVal).some(
          (key) => newVal[key].toString() != oldVal[key].toString(),
        );

        if (changed) {
          this.updateUrl();
        }
      },
      deep: true,
    },
  },
  computed: {
    filtersApplied() {
      return (
        JSON.stringify(this.filters) !=
        JSON.stringify(this.$constants.CONTACTS_DEFAULT_FILTERS)
      );
    },
  },
  data() {
    return {
      defaultContactProps: {
        id: -1,
        firstName: '',
        lastName: '',
        companyName: '',
        email: '',
        emergencyName: '',
        emergencyPhone: '',
        phone: '',
        jobTitle: '',
        status: 1,
      },
      items: [],
      itemsCount: 0,
      filters: {
        ...this.$constants.CONTACTS_DEFAULT_FILTERS,
      },
      options: {
        sortBy: ['name'],
        sortDesc: [false],
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
      },
      headers: [
        {
          text: 'Name',
          value: 'name',
          align: 'start',
          class: 'headerTextGrey--text lightGrey',
        },
        {
          text: 'Email',
          value: 'email',
          align: 'start',
          class: 'headerTextGrey--text lightGrey',
          width: '250px',
        },
        {
          text: 'Phone',
          value: 'phone',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '120px',
        },
        {
          text: 'Forms',
          value: 'formsCount',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '90px',
        },
        {
          text: 'Assigned Documents',
          value: 'documentsCount',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          width: '165px',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          class: 'headerTextGrey--text lightGrey',
          sortable: false,
          width: '140px',
        },
      ],
    };
  },
  methods: {
    gotoImport() {
      this.$router.push({ name: 'CompanyContactsImport' });
    },
    getItems() {
      const params = {
        loaderText: 'loading...',
        filters: this.filters,
        options: this.options,
      };
      const url = 'get-all-contacts?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          this.items = response.data.items;
          this.itemsCount = response.data.count;
        })
        .catch((error) => error);
    },
    applyFilters(filters) {
      this.filters = { ...filters };
      this.options.page = 1;
      this.updateUrl();
    },
    goToDetail(contact) {
      this.$store.commit('updateSelectedContact', contact);
      this.$router.push({
        name: 'ContactDetails',
        params: { id: contact.id },
      });
    },
    getRouteQueries() {
      const query = this.$route.query;
      this.filters = this.$helpers.deserializeFilters(query, this.filters);
      this.options = this.$helpers.deserializeOptions(query, 'name', false);

      this.getItems();
    },
    updateUrl() {
      let params = this.$helpers.serializeFilter(
        this.filters,
        this.$constants.CONTACTS_DEFAULT_FILTERS,
      );
      const options = this.$helpers.serializeOptions(
        this.options,
        'name',
        false,
      );
      params = { ...params, ...options };

      this.$router
        .replace({ name: this.$route.name, query: params })
        .catch(() => {});
    },
    onSearch(event) {
      this.filters.name = event;
      this.options.page = 1;
      this.updateUrl();
    },
    toggleStatus(contact) {
      const params = {
        id: contact.id,
        loaderText: 'loading...',
      };
      const url = contact.status
        ? 'delete-contact?format=json'
        : 'restore-contact?format=json';
      this.$axios
        .post(url, params)
        .then(() => {
          this.getItems();
        })
        .catch((error) => error);
    },
    goToAssignedDocuments(contactId) {
      this.$router.push({
        name: 'CompanyAssignedDocuments',
        query: { signedOffByContacts: [contactId].toString() },
      });
    },
    goToForms(contactId) {
      this.$router.push({
        name: 'CompanyForms',
        query: {
          signedOffByContactIds: [contactId].toString(),
          createdDateIntervalId: 1,
          headers: [
            'title',
            'projectName',
            'createdOnMobileAt',
            'completedBy',
            'signedOffByContact',
            'information',
          ].toString(),
          sortBy: 'createdOnMobileAt',
          sortDesc: 1,
        },
      });
    },
  },
  mounted() {
    this.getRouteQueries();
    this.$nextTick(() => {
      if (this.filters.name != '') {
        this.$refs.searchBar.search = this.filters.name;
      }
      this.$refs.filters.setFilters();
    });
  },
};
</script>

<style></style>
